import { useStore } from '@/store/index.js'
import { storeRemoveRouteFns } from '@/router/index.js'
import { getToken } from '@/utils/auth.js';
import { LOGIN_PATH, INDEX_PAGE_PATH } from '@/enumerators/route.js';
import whitePaths from './white-paths.js';
import { errorRoute } from './constant-routes.js';
import { getTitleThroughMatched } from './page-title.js'

const store = useStore();

export function createRouterGuards(router) {
  router.beforeEach(async (to, from, next) => {
    // console.log('Trigger router beforeEach.');

    const loadingBar = window.$loadingBar;
    loadingBar && loadingBar.start();
    const token = getToken();

    if (!!token) { // 已登录
      const menus = store.state.permission.menus;
      if (menus.length === 0) { // 无菜单 -> 添加菜单和可访问动态路由
        const removeRouteFns = [];
        try {
          // 获取菜单、生成可访问动态路由
          const accessAsyncRoutes = await store.dispatch('permission/getAccessAsyncRoutes');
          // 添加路由
          accessAsyncRoutes.forEach(route => {
            removeRouteFns.push(
              router.addRoute(route)
            );
          });
          // 添加404路由
          removeRouteFns.push(
            router.addRoute(errorRoute)
          );
          storeRemoveRouteFns(removeRouteFns);
          next({ ...to, replace: true });
          loadingBar && loadingBar.finish();
        } catch (e) {
          console.error(e);
          store.dispatch('permission/clearLoginInfo');
          next({ path: '/login', replace: true });
          loadingBar && loadingBar.finish();
        }
      } else if (to.path === LOGIN_PATH) {
        next(INDEX_PAGE_PATH);
        loadingBar && loadingBar.finish();
      } else {
        next();
      }
    } else { // 未登录
      if (whitePaths.includes(to.path)) {
        next();
      } else {
        next(LOGIN_PATH);
        loadingBar && loadingBar.finish();
      }
    }
  });

  router.afterEach(to => {
    document.title = getTitleThroughMatched(to.matched) || document.title;
    const loadingBar = window.$loadingBar;
    loadingBar && loadingBar.finish();
  });
}