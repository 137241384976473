export function getTitleThroughMatched(matched) {
  if (Array.isArray(matched)) {
    for (let i = matched.length - 1; i >= 0; i--) {
      if (matched[i]?.meta?.title) {
        return matched[i].meta.title;
      }
    }
  } else {
    return '';
  }
}