const modulesFiles = require.context('./async-route-modules', false, /\.js$/);
const modules = modulesFiles.keys().reduce((asyncRoutes, modulePath) => {
  const value = modulesFiles(modulePath);
  asyncRoutes.push(value.default);
  return asyncRoutes;
}, []);

// path 和本地路由的映射表
const pathRouteMap = (function () {
  const tempMap = {};
  function flatRoute(routes, parentPath) {
    if (Array.isArray(routes)) {
      routes.forEach(route => {
        const key = parentPath ? `${parentPath}/${route.path}` : route.path;
        tempMap[key] = route;
        // 有 associateMenu 的子路由不需要匹配权限菜单数据
        if (!(route.meta && !!route.meta.associateMenu)) {
          flatRoute(route.children, key);
        }
      });
    }
  }
  flatRoute(modules);
  return tempMap;
})();

let tempMenuRouteMap = {
  /*
  '/site': {
    title: '',
    link: '/site',
    icon: '',
    children: {
      '/site/main': {
        title: '',
        link: 'main',
        icon: ''
      }
    }
  }
  */
};

export function formTempRouteMap(menus, level = 1, lv1Title = '') {
  if (level === 1) {
    tempMenuRouteMap = {};
  }
  menus.forEach(menu => {
    if (menu.link) {
      const linkArr = menu.link.split('/');
      let tempParentRoute = null;
      let tempRoute = null;
      for (let i = 1, l = linkArr.length; i <= l; i++) {
        const link = `/${linkArr.slice(0, i).join('/')}`;
        if (i === 1) {
          tempParentRoute = tempMenuRouteMap;
        } else {
          tempParentRoute = tempRoute;
        }
        if (!tempParentRoute[link]) {
          if (i < l) {
            tempParentRoute[link] = {
              title: i === 1 ? lv1Title : '', // 设置一级标题
              link: i === 1 ? `/${linkArr[i - 1]}` : linkArr[i - 1],
              icon: menu.icon,
              children: {}
            };
          } else if (i === l) {
            tempParentRoute[link] = {
              title: menu.title, // 二级标题
              link: linkArr[i - 1],
              icon: menu.icon
            };
          }
        }
        tempRoute = tempParentRoute[link].children;
      }
    }
    if (Array.isArray(menu.children) && menu.children.length > 0) {
      formTempRouteMap(menu.children, level + 1, level === 1 ? menu.title : '');
    }
  });
  if (level === 1) {
    return tempMenuRouteMap;
  }
}

export function generateAccessAsyncRoutes(menuMap = tempMenuRouteMap) {
  const routes = [];
  const paths = Object.keys(menuMap);
  paths.forEach(path => {
    const localRoute = pathRouteMap[path];
    if (localRoute) {
      const menuRoute = menuMap[path];
      const menuRouteChildren = menuRoute.children && Object.keys(menuRoute.children).length > 0 ? menuRoute.children : null;
      const meta = localRoute.meta;
      meta.title = menuRoute.title;
      const route = {
        path: menuRoute.link,
        component: localRoute.component,
        meta
      }
      if (!!localRoute.meta && !!localRoute.meta.associateMenu) {
        // 优先添加关联路由（新增、编辑、删除等）
        route.children = localRoute.children;
      } else if (menuRouteChildren) {
        const routeChildren = generateAccessAsyncRoutes(menuRouteChildren);
        routeChildren.length && (route.children = routeChildren);
      }
      routes.push(route);
    }
  });
  return routes;
}

const _pathRedirectMap = {};

export function addRouteRedirect(routes, parentRoute) {
  routes.forEach(route => {
    const routeChildren = Array.isArray(route.children) && route.children.length ? route.children : null;
    if (routeChildren) {
      let redirectPrePath = '';
      if (parentRoute && parentRoute.redirect) {
        const redirectFrag = parentRoute.redirect.split('/');
        redirectFrag.pop();
        redirectFrag.push(route.path, routeChildren[0].path);
        redirectPrePath = redirectFrag.join('/');

        redirectFrag.pop();
        _pathRedirectMap[redirectFrag.join('/')] = redirectPrePath;
      } else {
        redirectPrePath = `${route.path}/${routeChildren[0].path}`;

        _pathRedirectMap[route.path] = redirectPrePath;
      }
      route.redirect = redirectPrePath;
      addRouteRedirect(routeChildren, route);
    }
  });
}

export function redirectMenuPath(menus) {
  if (Array.isArray(menus)) {
    menus.forEach(menu => {
      const redirect = _pathRedirectMap[`/${menu.link}`];
      if (!!redirect) {
        menu.link = redirect;
      }
      redirectMenuPath(menu.children);
    });
  }
}

export default modules;