export const resStatusEnum = {
  SUCCESS: 1,
  ERROR: 0
};

export const resErrStatusEnum = {
  TOKEN_EMPTY: 601,
  TOKEN_ERROR: 602,
  NO_LOGIN: 603,

  ACCOUNT_DISABLED: 604,
  ROLE_DISABLED: 605,

  NO_RIGHT: 606
};

export const reqParams = {
  TOKEN_KEY: 'Authorization',
  LOCAL_STORE_TOKEN_KEY: 'uu_a_t'
};

export const reqMethodEnum = {
  GET: 'get',
  POST: 'post'
};