// 无 layout 的路由
import { NoLayout } from '../constant-components.js';

export default {
  path: '/full-page',
  component: NoLayout,
  meta: {
    title: ''
  },
  children: [
    {
      path: 'teaching-preview',
      component: () => import('@/views/teaching/preview/preview.vue'),
      meta: {
        title: '资源预览'
      }
    },
    {
      path: 'paper-preview',
      component: () => import('@/views/paper/preview/preview.vue'),
      meta: {
        title: '试卷预览'
      }
    },
    {
      path: 'material-preview',
      component: () => import('@/views/material/preview/preview.vue'),
      meta: {
        title: '教材预览'
      }
    },
    {
      path: 'task-preview',
      component: () => import('@/views/task/preview/preview.vue'),
      meta: {
        title: '实训预览'
      }
    },
    {
      path: 'course-preview',
      component: () => import('@/views/material/preview/preview.vue'),
      meta: {
        title: '课程预览'
      }
    },
    {
      path: 'ad-details',
      component: () => import('@/views/banner/details.vue'),
      meta: {
        title: '广告详情'
      }
    },
    {
      path: 'problem-details',
      component: () => import('@/views/problems/details.vue'),
      meta: {
        title: '问题详情'
      }
    },
    {
      path: 'feedback-details',
      component: () => import('@/views/feedback/details.vue'),
      meta: {
        title: '离线留言详情'
      }
    }
  ]
};