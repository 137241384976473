import { createRouter, createWebHistory } from "vue-router";
import { constantRoutes } from './constant-routes.js';
import { createRouterGuards } from './router-guards.js';

function createRouterFn() {
  return createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: constantRoutes,
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { left: 0, top: 0 };
      }
    }
  });
}

const router = createRouterFn();

export function initRouter(app) {
  app.use(router);
  createRouterGuards(router);
}

let _removeRouteFns = [];
export function storeRemoveRouteFns(removeRouteFns) {
  _removeRouteFns = removeRouteFns;
}

export function resetRouter() {
  _removeRouteFns.forEach(removeRouteFn => removeRouteFn());
}

export function useRouter() {
  return router;
}

export default router;
