<template>
  <NConfigProvider
    :locale="zhCN"
    :date-locale="dateZhCN"
    :theme="useDarkTheme"
    :theme-overrides="themeOverrides"
  >
    <Application>
      <RouterView />
    </Application>
  </NConfigProvider>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex'
import { NConfigProvider, darkTheme, zhCN, dateZhCN } from 'naive-ui';
import Application from '@/components/Application/index.vue';
import { lighten } from '@/utils/color.js';

const store = useStore();

const useDarkTheme = computed(() => (store.state.themeSettings.useDarkTheme ? darkTheme : undefined));
const themeOverrides = computed(() => {
  const appThemeColor = store.state.themeSettings.appThemeColor;
  const lightenStr = lighten(appThemeColor, 6);

  return {
    common: {
      primaryColor: appThemeColor,
      primaryColorHover: lightenStr,
      primaryColorPressed: lightenStr,
    },
    LoadingBar: {
      colorLoading: appThemeColor,
    },
  };
});

</script>

<style lang="less">
@import "~@/styles/common.less";
</style>
