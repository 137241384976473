// 广告管理
import { Layout, NoLayout } from '../constant-components.js';

export default {
  path: '/banner',
  redirect: '',
  component: Layout,
  meta: {
    title: ''
  },
 
children: [
  {
    path: 'index',
    component: NoLayout,
    meta: {
      title: '广告管理',
      associateMenu: 'banner/index'
    },
    children: [
      {
        path: 'index',
        component: () => import('@/views/banner/index.vue'),
        meta: {
          title: ''
        }
      },
      {
        path: 'addedit',
        component: () => import('@/views/banner/addedit.vue'),
        meta: {
          title: '广告新增-编辑'
        },
      }
    ]
    
  }
]
    
 
};