import axios from 'axios';
import qs from 'qs';
import { getToken } from '@/utils/auth.js';
import env from '@/settings/env.js';
import { resStatusEnum, resErrStatusEnum } from '@/enumerators/http.js';
import { useRouter } from '@/router/index.js';
import { useStore } from '@/store/index.js';
import { LOGIN_PATH } from '@/enumerators/route.js';
const { apiUrlPrefix } = env;
const { SUCCESS, ERROR } = resStatusEnum;
const {
  TOKEN_EMPTY,
  TOKEN_ERROR,
  NO_LOGIN,
  ACCOUNT_DISABLED,
  ROLE_DISABLED,
  NO_RIGHT
} = resErrStatusEnum;

let _showResMsg = true;

const showErrMsg = (msgErrFn, errMsg) => {
  if (Array.isArray(errMsg)) {
    errMsg.forEach(msg => msgErrFn(msg));
  } else {
    msgErrFn(errMsg);
  }
};

const request = axios.create({
  baseURL: apiUrlPrefix,
  timeout: 15000
});

request.interceptors.request.use(
  config => {
    const token = getToken();
    token && (config.headers.Authorization = token);
    // 加载提示 - 开启
    if (!(config.data instanceof FormData)) {
      config.data = qs.stringify(config.data); // 转为formdata数据格式
    }
    return config;
  },
  error => {
    console.log('Request error:', error);
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  response => {
    const message = window.$message;
    // 加载提示 - 关闭
    const res = response.data;
    
    switch (res.code) {
      case SUCCESS:
        return res;
      case ERROR:
        if (res.data) {
          const store = useStore();
          const router = useRouter();
          switch (res.data.status) {
            case TOKEN_EMPTY:
            case TOKEN_ERROR:
            case NO_LOGIN:
            case ACCOUNT_DISABLED:
            case ROLE_DISABLED:
              _showResMsg && message.error(res.msg || '登录状态超时，即将跳转登录页', {
                duration: 4000
              });
              store.dispatch('permission/clearLoginInfo');
              setTimeout(() => {
                router.replace(LOGIN_PATH);
                _showResMsg = true;
              }, 3500);
              _showResMsg = false;
              // return Promise.reject(res.msg);
              break;
            case NO_RIGHT:
              message.error(res.msg);
              // return Promise.reject(res.msg);
              break;
            default:
              showErrMsg(message.error, res.msg);
          }
        } else {
          showErrMsg(message.error, res.msg);
        }
        return res;
    }
  },
  error => {
    const message = window.$message;
    // 加载提示 - 关闭
    message.error(error instanceof Error ? error.message : error);
    console.log('Response error:', error);
    return Promise.reject(error);
  }
);

export default request;