import request from '@/utils/request.js';
import { reqMethodEnum } from '@/enumerators/http.js'
const { GET, POST } = reqMethodEnum;

export function login(data) {
  return request({
    url: '/backend/web/login',
    method: POST,
    data
  });
}

export function logout(data) {
  return request({
    url: '/backend/web/site/logout',
    method: POST,
    data
  });
}

export function getMenus(data) {
  return request({
    url: '/backend/web/system-power/menus-sidebar',
    method: GET,
    params: data
  });
}