// 课程管理
import { Layout, NoLayout } from '../constant-components.js';

export default {
  path: '/course',
  redirect: '',
  component: Layout,
  meta: {
    title: ''
  },
 
children: [
  {
    path: 'index',
    component: NoLayout,
    meta: {
      title: '课程列表',
      associateMenu: 'course/index'
    },
    children: [
      {
        path: 'index',
        component: () => import('@/views/course/index.vue'),
        meta: {
          title: ''
        }
      },
      {
        path: 'build',
        component: () => import('@/views/course/build/build.vue'),
        meta: {
          title: '课程建设'
        },
      },
      {
        path: 'details',
        component: () => import('@/views/course/details.vue'),
        meta: {
          title: '详情'
        },
      }
    ]
  }
]
    
 
};